import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledCreateButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { CheckPointListTable } from "./CheckPointListTable";
import { MenuProps } from "../../Styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import IconButton from '@mui/material/IconButton';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

export const CheckPointList = ({ isDarkMode, isWidget, removeWidget, id }) => {
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const dispatch = useDispatch();
    const siteData = useSelector(state => state.userRole.siteData);
    useEffect(() => {
        dispatch(fetchSites());
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
            const siteId = sessionStorage.getItem('user_site_id');
            if(siteId != 0){
                setSelectedSites(siteId)
            }

        }
    }, [siteData]);
    const handleCreate = () => {
        window.location.href = './tracking/checkpoint/create'
    }
    return (
        <>
            <Grid container>
                {isWidget &&
                    < Grid container sx={{ mt: 2 }}>
                        <Grid item lg={9} md={9} sm={10} xs={10} className="mtdash2" >
                            <Typography className='headersubhead light_text htl'>CheckPoint List</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2} lg={3} md={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                                <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                            </IconButton>
                        </Grid>
                        <Grid item lg={6} sm={12} xs={12} md={6}
                        sx={{mt:2}}
                            style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Grid item lg={12} sm={12} xs={12} md={12} className="mt2"
                                style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Grid item sx={{ mt: 2 }}>
                                    <Typography sx={{ textAlign: 'center' }}
                                        className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                        Sites :
                                    </Typography>

                                </Grid>
                                <Grid item lg={8} sm={9} xs={9} md={8}>
                                    <Select
                                        //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                        sx={{
                                            color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                            textAlign: 'left',
                                            '.MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                                border: '',
                                            },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'rgba(233, 233, 233, 1)',
                                            },
                                            '.MuiSvgIcon-root ': {
                                                fill: isDarkMode ? "white !important" : "#161616",
                                            }
                                        }}

                                        displayEmpty
                                        MenuProps={MenuProps}
                                        className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                        value={selectedSites}
                                        onChange={(e) => setSelectedSites(e.target.value)}
                                    >   <MenuItem value={''}>All</MenuItem>
                                        {sites.map((site, index) => (
                                            <MenuItem
                                                key={index}
                                                value={site.organizationId}>
                                                {site.organizationName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </Grid>

                            </Grid>
                        </Grid>
                       
                    </Grid>
                }
                {!isWidget &&
                <>
                    <Grid item lg={6} sm={12} xs={12} md={6} sx={{ml:2}}>
                        <Grid container className="mt2">
                            <Grid item sx={{ mt: 2 }}>
                                <Typography sx={{ textAlign: 'center' }}
                                    className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                    Sites :
                                </Typography>

                            </Grid>
                            <Grid item lg={4} sm={8} xs={8} md={4}>
                                <Select
                                    //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                    sx={{
                                        color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 100,
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                            border: '',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'rgba(233, 233, 233, 1)',
                                        },
                                        '.MuiSvgIcon-root ': {
                                            fill: isDarkMode ? "white !important" : "#161616",
                                        }
                                    }}

                                    displayEmpty
                                    MenuProps={MenuProps}
                                    className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                    value={selectedSites}
                                    onChange={(e) => setSelectedSites(e.target.value)}
                                >   <MenuItem value={''}>All</MenuItem>
                                    {sites.map((site, index) => (
                                        <MenuItem
                                            key={index}
                                            value={site.organizationId}>
                                            {site.organizationName}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </Grid>

                        </Grid>
                    </Grid>
                <Grid item lg={5.7} sm={12} xs={12} md={5.7} container className="alignR" 
                        sx={{'@media (max-width: 600px)': { disply: 'flex', justifyContent: 'flex-start !important',mt:'5px !important',ml:'5% !important' }}}>

                  
                        <StyledCreateButton
                            variant="contained"
                            disableRipple
                            startIcon={<MdOutlineAddToPhotos color="white" />}
                            onClick={handleCreate}
                        >

                            Create CheckPoint
                        </StyledCreateButton>
                   
                </Grid>
                </>}
                {/* {isWidget &&
                  
                } */}
            </Grid>
            <Grid Container sx={{ mt: 5 }}>
                <Grid item lg={12} md={12}>
                    <CheckPointListTable isDarkMode={isDarkMode} selectedSites={selectedSites} />

                </Grid>
            </Grid>
        </>
    )
}