import React, { useEffect, useRef, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { Alert, Grid, IconButton, MenuItem, Select, Snackbar, Typography, useMediaQuery } from "@mui/material";
import { MenuProps, Search, SearchIconWrapper, StyledInputBase } from "../../Styles";
import { StyledCreateButton, YellowBorderButton } from "../../styles/ButtonStyles";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RxDownload } from "react-icons/rx";
import { PersonnelListTable } from "./PersonnelListTable";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchSites } from "../../../redux/actions/UserRoleActions";
import { PersonnelImportModal } from "../../Modals/PersonnelImportmodal";
import * as XLSX from "xlsx";
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import dayjs from "dayjs";
import SyncIcon from '@mui/icons-material/Sync';
export const PersonnelList = ({ isDarkMode, isWidget, removeWidget, id }) => {

    const fileInputRef = useRef(null);
    const [sites, setSites] = useState([]);
    const [selectedSites, setSelectedSites] = useState('');
    const [dataFromExcel, setDataFromExcel] = useState([]);
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [showSnack, setShowSnack] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const { open, vertical, horizontal } = showSnack;
    const [snackMessage, setSnackMessage] = useState('');
    const [importModalOpen, setImportModalOpen] = useState(false);
    const siteData = useSelector(state => state.userRole.siteData);
    useEffect(() => {

        dispatch(fetchSites());
    }, []);
    useEffect(() => {
        if (!siteData.isFetching && siteData.payload && siteData.payload.success === true) {
            const siteList = siteData.payload.data.SiteList;
            const temp = siteList.map((sites) => {
                return {
                    networkId: sites.networkId,
                    organizationId: sites.organizationId,
                    organizationName: sites.organizationName,
                    timestamp: sites.timestamp
                }
            })
            setSites(temp)
            const siteId = sessionStorage.getItem('user_site_id');
            setSelectedSites(siteId)
        }
    }, [siteData]);
    const handleCreate = () => {
        window.location.href = './tracking/personnel/create';
    }
    const handleImportModal = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            // No file selected, return or handle the case accordingly
            setImportModalOpen(false);
            return;
        }
        const reader = new FileReader();
        reader.readAsBinaryString(e.target.files[0]);



        // const reader = new FileReader();
        //  reader.readAsBinaryString(e.target.files[0]);

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary", cellDates: true });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];


            // Extract headers from the first row of the Excel sheet
            const headers = [];
            const range = XLSX.utils.decode_range(sheet['!ref']);
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
                const cellValue = sheet[cellAddress] ? sheet[cellAddress].v : '';
                headers.push(cellValue);
            }
            const requiredColumns = ["Name", "Employee Id", "Name of Awarded Contractor",
                "Supervisor Phone No", "Supervisor Name", "Project Manager", "End Date & Time", "Start Date & Time", "Project title",
                "Domain/ Unit", "Permit Serial Number",
                // "Site Id", "Sites", 
                "Phone No", "Mail Id"];
            const missingColumns = requiredColumns.filter(column => !headers.includes(column));

            if (missingColumns.length > 0) {

                let alert = `Missing columns: ${missingColumns.join(", ")}`;
                setSnackMessage(alert);
                setShowSnack({ ...showSnack, open: true })
            }

            else {
                const dateOptions = {
                    header: 1,
                    cellDates: true
                };
                const parsedData = XLSX.utils.sheet_to_json(sheet, { defval: 'NA' }, dateOptions);


                let hasIssues = false;

                parsedData.forEach(row => {
                    if (hasIssues) return;
                    if (row['Name'].trim() === '' || row['Name'].trim().toUpperCase() == 'NA') {
                        let alert = `Name field is mandatory for row: ${JSON.stringify(row)}`;
                        hasIssues = true;
                        setSnackMessage(alert);
                        setShowSnack({ ...showSnack, open: true });
                        return;
                    }
                    if (row['Employee Id'].trim() === '' || row['Employee Id'].trim().toUpperCase() == 'NA') {
                        let alert = `Employee Id field is mandatory for row: ${JSON.stringify(row)}`;
                        hasIssues = true;
                        setSnackMessage(alert);
                        setShowSnack({ ...showSnack, open: true });
                        return;
                    }


                    if (typeof row['Start Date & Time'] === 'string' && row['Start Date & Time'].trim() !== '' && row['Start Date & Time'].trim().toUpperCase() !== 'NA') {
                        let startdate = row['Start Date & Time'];
                        hasIssues = !dayjs(startdate, 'DD/MM/YYYY hh:mm A', true).isValid();
                        if (hasIssues) {
                            let alert = `Invalid date format in Start Date & Time column for row: ${JSON.stringify(row)}`;
                            setSnackMessage(alert);
                            setShowSnack({ ...showSnack, open: true });
                            return;
                        }
                    }
                    if (typeof row['End Date & Time'] === 'string' && row['End Date & Time'].trim() !== '' && row['End Date & Time'].trim().toUpperCase() !== 'NA') {
                        let enddate = row['End Date & Time'];
                        hasIssues = !dayjs(enddate, 'DD/MM/YYYY hh:mm A', true).isValid();
                        if (hasIssues) {
                            let alert = `Invalid date format in End Date & Time column for row: ${JSON.stringify(row)}`;
                            setSnackMessage(alert);
                            setShowSnack({ ...showSnack, open: true });
                            return;
                        }
                    }
                    if (typeof row['Permit Issuance Date'] === 'string' && row['Permit Issuance Date'].trim() !== '' && row['Permit Issuance Date'].trim().toUpperCase() !== 'NA') {
                        let permitDate = row['Permit Issuance Date'];
                        hasIssues = !dayjs(permitDate, 'DD/MM/YYYY', true).isValid();
                        if (hasIssues) {
                            let alert = `Invalid date format in Permit Issuance Date column for row: ${JSON.stringify(row)}`;
                            setSnackMessage(alert);
                            setShowSnack({ ...showSnack, open: true });
                            return;
                        }
                    }
                });

                if (!hasIssues && showSnack.open == false) {
                    setDataFromExcel(parsedData);
                    if (parsedData.length) {
                        setImportModalOpen(true);
                    }
                } else {
                    setImportModalOpen(false);
                }
            }
        }
    }

    const handlePersonDelete = (id) => {
        let temp = [...dataFromExcel];
        let filteredData = [];
        temp.map((item, index) => {
            if (item["Employee Id"] != id) {
                filteredData.push(item)
            }
        })
        setDataFromExcel(filteredData);
    }
    const CloseImportModal = () => {
        setImportModalOpen(false);
    }
    const CloseSuccessImportModal = () => {
        setImportModalOpen(false);
        sessionStorage.setItem('trackingTab', 'three')
        // window.location.reload();

        window.location.href = '/tracking';
    }
    const closeSnack = () => {
        setShowSnack({ ...showSnack, open: false });
    };
    const handleRefresh = () => {
        window.location.reload();
    };
    return (
        <>
            <Grid container sx={{ mt: 2 }} columnSpacing={{ lg: 4, md: 1 }} rowSpacing={{ sm: 3, xs: 3 }}>
                <Grid item lg={3.3} md={3.3} sm={10} xs={10} sx={{ ml: '0%', pl: '1% !important', mt: { lg: 0, md: 0, sm: 2, xs: 2 } }}>
                    <Search isDarkMode={isDarkMode}>
                        <SearchIconWrapper>
                            <SearchIcon sx={{ color: isDarkMode ? "white" : "#8F8F91" }} fontSize="medium" className="mb" />
                        </SearchIconWrapper>
                        <StyledInputBase
                            isDarkMode={isDarkMode}
                            placeholder="Search Name/Employee ID"
                            inputProps={{ 'aria-label': 'search' }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Search>
                </Grid>
                <Grid item lg={2} md={2} sm={12} xs={12}>
                    <Grid container className="mt2">
                        <Grid item sx={{ mt: 2 }}>
                            <Typography sx={{ textAlign: 'center' }}
                                className={isDarkMode ? "headersubhead chartheading_color" : "headersubhead light_text"}>
                                Sites :
                            </Typography>
                        </Grid>
                        <Grid item lg={4} sm={8} xs={8} md={4}>
                            <Select
                                //   className={isDarkMode ? "chart-select select-wrap selectbg" : "selectbg_light chart-select select-wrap"}
                                sx={{
                                    color: isDarkMode ? "white" : "#161616", ml: 1, minWidth: 140,
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                        border: '',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'rgba(233, 233, 233, 1)',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: isDarkMode ? "white !important" : "#161616",
                                    }
                                }}

                                displayEmpty
                                MenuProps={MenuProps}
                                className={isDarkMode ? "headerSelect select_dark headerhead_dark" : "headerSelect select_light"}
                                value={selectedSites}
                                onChange={(e) => setSelectedSites(e.target.value)}
                            >
                                <MenuItem value={''}>All</MenuItem>
                                {sites.map((site, index) => (
                                    <MenuItem
                                        key={index}
                                        value={site.organizationId}>
                                        {site.organizationName}
                                    </MenuItem>
                                ))}


                            </Select>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} container className=""
                    sx={{ ml: { lg: '4% !important', md: '4% !important', sm: 0, xs: 0 } }}
                >
                    {!isWidget &&
                        <StyledCreateButton
                            variant="contained"
                            disableRipple
                            startIcon={<MdOutlineAddToPhotos color="white" />}
                            onClick={handleCreate}
                        >

                            Create Personnel
                        </StyledCreateButton>
                    }
                </Grid>
                <Grid item lg={3} md={3} sm={10} xs={12} container className=" import_btn_grid importbtnspace "
                    sx={{ ml: { lg: 0, md: 0, sm: '1% !important', xs: '1% !important' } }}

                >
                    {!isWidget &&
                        <div class="file-input-container"  >
                            <label for="file-input" className=" import_btn" >
                                <IconButton sx={{ ml: "2px" }}>
                                    <RxDownload color="#FFAC0B" />
                                </IconButton>
                                Import Personnel
                                <span style={{ marginLeft: "10px" }}></span>
                            </label>
                            <input
                                type="file"
                                id="file-input"
                                accept=".xlsx, .xls"
                                onChange={handleImportModal}
                                onClick={(e) => {
                                    e.target.value = null
                                }}
                                style={{ display: 'none' }}
                            />
                        </div>
                    }
                </Grid>
                {/* {!isWidget &&
                    <Grid item xs={6} sm={6} lg={7} md={7} style={{ display: 'flex', justifyContent: 'flex-end' }}
                       // sx={{ ml: '4% !important' }}
                    >
                        <YellowBorderButton
                          startIcon={<SyncIcon style={{ color:'black'}} />}
                        >

                        </YellowBorderButton>
                    </Grid>
                } */}
                {isWidget &&
                    <Grid item xs={1} sm={1} lg={1} md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="settings" onClick={() => { removeWidget(id); }}>
                            <IndeterminateCheckBoxOutlinedIcon style={{ color: '#FFAC0B' }} />
                        </IconButton>
                    </Grid>
                }
            </Grid>
            <Grid Container sx={{ mt: 5 }}>
                <Grid item lg={12} md={12}>
                    <PersonnelListTable
                        isDarkMode={isDarkMode}
                        selectedSites={selectedSites}
                        searchWord={search}
                        handleRefresh={handleRefresh}

                    />
                </Grid>
            </Grid>
            <PersonnelImportModal
                importModalOpen={importModalOpen}
                handleImportModal={handleImportModal}
                CloseSuccessImportModal={CloseSuccessImportModal}
                dataFromExcel={dataFromExcel}
                handlePersonDelete={handlePersonDelete}
                CloseImportModal={CloseImportModal}
                isWidget={isWidget}
            />
            {showSnack ?
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={open}
                    onClose={closeSnack}
                    autoHideDuration={10000}
                //  key={vertical + horizontal}
                >
                    <Alert
                        onClose={closeSnack}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackMessage}
                    </Alert>
                </Snackbar> : <></>
            }
        </>
    )
}