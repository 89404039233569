import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { useDispatch } from "react-redux";
import { Alert, CircularProgress, Grid, Typography } from "@mui/material";
import { AlertDeatils } from "./AlertDetails";
import { UserDetails } from "./UserDetails";
import { ViolationDetails } from "./ViolationDetails";
import { AssignAlert } from "./AssignAlert";
import {
  assignAlert,
  clearAssignAlertStatus,
  fetchSingleCameraAlert,
  fetchSingleDeviceAlert,
  fetchSinglePatrol,
  fetchSinglesensorAlert,
  fetchSingleSentry,
} from "../../../redux/actions/AlertActions";
import { useSelector } from "react-redux";
import {
  alertTimeOnly,
  convertMinutesToTime,
  dateNeglector,
  dateOnly,
  extractDatefromTimeStamp,
  timeDifference,
  timeOnly,
  utcDateTimeToLocalTime,
} from "../../convertors/TimeConvertor";
import dayjs from "dayjs";
import { AssignedDeatails } from "./AssignedDetails";
import { useParams } from "react-router-dom";
import { SensorDetails } from "./SensorDetails";
import { EncoderDetails } from "./EncoderDetails";
import { VaViolationProof } from "./VaViolationProof";

export const AlertView = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const patrolDetails = useSelector((state) => state.alert.singlePatrol);
  const sentryDetails = useSelector((state) => state.alert.singleSentry);
  const deviceDetails = useSelector((state) => state.alert.singleDevice);
  const sensorDetails = useSelector((state) => state.alert.singleSensor);
  const cameraDetails = useSelector((state) => state.alert.singleCamera);
  const [vaViolationProof, setVaViolationProof] = useState({
    imgUrl: "",
    videoUrl: ""
  })
  const assignAlertStatus = useSelector((state) => state.alert.assignAlert);
  const [showAlert, setShowAlert] = useState(false);
  const [assigndDate, setAssignedDate] = useState({
    date: "",
    time: "",
  });
  const [isDarkMode, setDarkMode] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("alert");
  const [AlertDetails, setAlertDetails] = useState({});
  const [AlertDate, setAlertDate] = useState("");
  const [alertAssignDetails, setAlertAssignDetails] = useState({
    id: id,
    verified: 1,
    verified_user: sessionStorage.getItem("id"),
    duty_type: 0,
    violation_type: 0,
    description: "",
  });
  const [unAuthDuration, setUnAuthDuration] = useState("");
  useEffect(() => {
    // const id = sessionStorage.getItem("alertViewId");
    // const type = sessionStorage.getItem("alert_type");

    if (type == 2) {
      dispatch(fetchSinglePatrol(id));
    }
    if (type == 1) {
      dispatch(fetchSingleSentry(id));
    }
    if (type == 0) {
      dispatch(fetchSingleDeviceAlert(id));
    }
    if (type == 5) {
      dispatch(fetchSinglesensorAlert(id));
    }
    if (type == 4) {
      dispatch(fetchSingleCameraAlert(id));
    }
  }, []);
  const dateFormat = (date) => {
    let formattedDate = dayjs(date);

    return formattedDate.format("MM/DD/YYYY");
  };
  useEffect(() => {
    if (
      !patrolDetails.isFetching &&
      patrolDetails.payload &&
      patrolDetails.payload.success === true
    ) {
      const temp = patrolDetails.payload.data;

      const alert_date = dateOnly(temp.date);
      const temp_alert_date = dateFormat(alert_date);
      if (temp && temp.verified == 1) {
        let tempDate = { ...assigndDate };
        let temp_updated = dateOnly(temp.updated_at);
        let temp_updated_time = utcDateTimeToLocalTime(temp.updated_at);


        tempDate["date"] = dateFormat(temp_updated);
        tempDate["time"] = temp_updated_time;

        setAssignedDate(tempDate);
      }
      if (
        temp &&
        temp.violationreason &&
        temp.violationreason.length > 0 &&
        temp.violationreason[0].lastseenat
      ) {
        let lastSeentime = temp.violationreason[0].lastseenat;
        let firstSeentime = temp.violationreason[0].firstseenat;
        const unauthDuration = timeDifference(firstSeentime, lastSeentime);
        setUnAuthDuration(unauthDuration);
      }
      setAlertDetails(temp);
      setAlertDate(temp_alert_date);
      const temAssign = {
        ...temp,
        violation_type: temp.alert_type,
      };
      setAlertAssignDetails(temAssign);
    }
    if (
      !sentryDetails.isFetching &&
      sentryDetails.payload &&
      sentryDetails.payload.success === true
    ) {
      const temp = sentryDetails.payload.data;

      const alert_date = dateOnly(temp.date);
      const temp_alert_date = dateFormat(alert_date);
      if (temp && temp.verified == 1) {
        let tempDate = { ...assigndDate };
        let temp_updated = dateOnly(temp.updated_at);
        let temp_updated_time = utcDateTimeToLocalTime(temp.updated_at);

        tempDate["date"] = dateFormat(temp_updated);
        tempDate["time"] = temp_updated_time;

        setAssignedDate(tempDate);
      }
      if (
        temp &&
        temp.violationreason &&
        temp.violationreason.length > 0 &&
        temp.violationreason[0].lastseenat
      ) {
        // let lastSeentime = alertTimeOnly(temp.violationreason[0].lastseenat);
        // let firstSeentime = alertTimeOnly(temp.violationreason[0].firstseenat);
        let lastSeentime = temp.violationreason[0].lastseenat;
        let firstSeentime = temp.violationreason[0].firstseenat;
        const unauthDuration = timeDifference(firstSeentime, lastSeentime);
        setUnAuthDuration(unauthDuration);
      }
      const tempAlertData = {
        ...temp,
        start_time_sentry:
          temp.sentry_starttime == 0 || temp.sentryreportid == 0
            ? "00:00"
            : alertTimeOnly(temp.sentry_starttime),
        end_time_sentry:
          temp.sentry_endtime == 0 || temp.sentryreportid == 0
            ? "00:00"
            : alertTimeOnly(temp.sentry_endtime),
      };
      setAlertDetails(tempAlertData);
      setAlertDate(temp_alert_date);
      const temAssign = {
        ...temp,
        violation_type: temp.alert_type,
      };
      setAlertAssignDetails(temAssign);
    }
    if (
      !deviceDetails.isFetching &&
      deviceDetails.payload &&
      deviceDetails.payload.success === true
    ) {
      const temp = deviceDetails.payload.data;
      const alert_date = dateOnly(temp.date);
      const temp_alert_date = dateFormat(alert_date);

      if (temp && temp.verified == 1) {
        let tempDate = { ...assigndDate };
        let temp_updated = dateOnly(temp.updated_at);
        let temp_updated_time = utcDateTimeToLocalTime(temp.updated_at);


        tempDate["date"] = dateFormat(temp_updated);
        tempDate["time"] = temp_updated_time;

        setAssignedDate(tempDate);
      }
      setAlertDetails(temp);
      setAlertDate(temp_alert_date);
      const temAssign = {
        ...temp,
        violation_type: temp.alert_type,
      };
      setAlertAssignDetails(temAssign);
    }
    if (
      !sensorDetails.isFetching &&
      sensorDetails.payload &&
      sensorDetails.payload.success === true
    ) {
      const temp = sensorDetails.payload.data;
      const alert_date = dateOnly(temp.date);
      const temp_alert_date = dateFormat(alert_date);
      if (temp && temp.verified == 1) {
        let tempDate = { ...assigndDate };
        let temp_updated = dateOnly(temp.updated_at);
        let temp_updated_time = utcDateTimeToLocalTime(temp.updated_at);


        tempDate["date"] = dateFormat(temp_updated);
        tempDate["time"] = temp_updated_time;

        setAssignedDate(tempDate);
      }
      setAlertDetails(temp);
      setAlertDate(temp_alert_date);
      const temAssign = {
        ...temp,
        violation_type: temp.alert_type,
      };
      setAlertAssignDetails(temAssign);
    }
    if (
      !cameraDetails.isFetching &&
      cameraDetails.payload &&
      cameraDetails.payload.success === true
    ) {
      const temp = cameraDetails.payload.data;
      const alert_date = extractDatefromTimeStamp(temp.timestamp);
      // let tempProof ={...vaViolationProof};
      // if (temp.image_link !== "") {
      //   const tempUrl = process.env.REACT_APP_API_BASE_URL + '/media/' + temp.image_link;
      //   tempProof["imgUrl"] = tempUrl    
      // }
      // if (temp.video_link !== "") {
      //   const temVideopUrl = 'https://apx-bucket.s3.us-east-2.amazonaws.com/'+ temp.video_link;
      //   tempProof["videoUrl"] = temVideopUrl    
      // }
      // setVaViolationProof(tempProof);
      const temp_alert_date = dateFormat(alert_date);
      if (temp && temp.verified == 1) {
        let tempDate = { ...assigndDate };
        let temp_updated = dateOnly(temp.updated_at);
        let temp_updated_time = utcDateTimeToLocalTime(temp.updated_at);


        tempDate["date"] = dateFormat(temp_updated);
        tempDate["time"] = temp_updated_time;

        setAssignedDate(tempDate);
      }
      setAlertDetails(temp);
      setAlertDate(temp_alert_date);
      const temAssign = {
        ...temp,
        violation_type: temp.alert_type,
      };
      setAlertAssignDetails(temAssign);
    }
    if (
      !assignAlertStatus.isFetching &&
      assignAlertStatus.payload &&
      assignAlertStatus.payload.success === true
    ) {
      window.location.href = "/alert";
    }
    if (!assignAlertStatus.isFetching && assignAlertStatus.error) {
      setShowAlert(true);
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
        dispatch(clearAssignAlertStatus());
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [patrolDetails, sentryDetails, deviceDetails, assignAlertStatus, sensorDetails, cameraDetails]);
  const handleDarkmode = () => {
    setDarkMode(!isDarkMode);
  };
  const handleActiveMenuItems = (menuItems) => {
    setActiveMenuItem(menuItems);
    // window.location.href='/dashboard';
  };
  const onAssignChange = (value, key) => {
    let temp = { ...alertAssignDetails };
    temp[key] = value;
    setAlertAssignDetails(temp);
  };
  const handleDiscard = () => {
    let temp = { ...alertAssignDetails };
    temp["violation_type"] = 0;
    temp["description"] = "";
    setAlertAssignDetails(temp);
  };
  const onAssignSubmit = () => {
    let data = {
      id: id,
      verified: 1,
      verified_user: parseInt(sessionStorage.getItem("id")),
      type: AlertDetails.duty_type,
      violation_type:
        alertAssignDetails.violation_type == 0
          ? AlertDetails.alert_type
          : alertAssignDetails.violation_type,
      description: alertAssignDetails.description,
      created_user: parseInt(sessionStorage.getItem("id")),
      org_id: parseInt(sessionStorage.getItem("org_Id")),
    };
    dispatch(assignAlert(data));
  };
  return (
    <Layout
      isDarkMode={isDarkMode}
      handleDarkmode={handleDarkmode}
      handleActiveMenuItems={handleActiveMenuItems}
      activeMenuItem={"alert"}
      activeMenuKey={"Alerts"}
      isCreate={true}
    >
      <div
        style={{ marginLeft: "-1.5%", marginRight: "2%", marginTop: "3%", '@media (max-width: 600px)': { marginLeft: "0% !important" } }}
        className=""
      >
        {patrolDetails.isFetching || sentryDetails.isFetching || deviceDetails.isFetching || sensorDetails.isFetching || cameraDetails.isFetching ?
          <Grid item lg={12} md={12} sm={12} xs={12} container
            sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
            <CircularProgress sx={{ color: '#FFAC0B' }} />
          </Grid>
          :
          <Grid container 
          sx={{ mt: 5,ml: { lg: '3% !important', md: '3% !important', sm: '-1% !important', xs: '-1% !important' }}} spacing={2}>
            <Grid item lg={11} md={11} sm={12} xs={12} container>
              <Grid item lg={10} sm={12} xs={12} md={10}>
                <Typography
                  sx={{ textAlign: "left", mt: 2 }}
                  className={
                    isDarkMode
                      ? "headersubhead  chartheading_color"
                      : "headersubhead  light_text"
                  }
                >
                  Alert & Site Details
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
              <AlertDeatils AlertDetails={AlertDetails} AlertDate={AlertDate} />
            </Grid>
            {AlertDetails.duty_type !== 5 && AlertDetails.duty_type != 4 && (
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    User Details
                  </Typography>
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                  <UserDetails AlertDetails={AlertDetails} />
                </Grid>
              </>
            )}
            {AlertDetails.duty_type == 5 && (
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    Sensor Details
                  </Typography>
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                  <SensorDetails AlertDetails={AlertDetails} />
                </Grid>
              </>
            )}
            {AlertDetails.duty_type == 4 && (
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    Encoder Details
                  </Typography>
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                  <EncoderDetails AlertDetails={AlertDetails} />
                </Grid>
              </>
            )}
            {AlertDetails.duty_type == 4 && (
              <VaViolationProof AlertDetails={AlertDetails}
                vaViolationProof={vaViolationProof}
              />
            )}
            {AlertDetails.duty_type !== 0 && AlertDetails.RouteData != null ? (
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    Violation Details
                  </Typography>
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                  <ViolationDetails
                    AlertDetails={AlertDetails}
                    unAuthDuration={unAuthDuration}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {AlertDetails.verified == 1 && (
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    Assigned Details
                  </Typography>
                </Grid>
                <Grid item lg={11} md={11} sm={12} xs={12} sx={{ mt: 3 }}>
                  {" "}
                  <AssignedDeatails
                    AlertDetails={AlertDetails}
                    assigndDate={assigndDate}
                  />
                </Grid>
              </>
            )}
            {
             
            AlertDetails.verified == 0 && (
              assignAlertStatus.isFetching ?
              <Grid item lg={12} md={12} sm={12} xs={12} container
                        sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
                        <CircularProgress sx={{ color: '#FFAC0B' }} />
                    </Grid>
              :
              <>
                <Grid item lg={11} sm={12} xs={12} md={11}>
                  <Typography
                    sx={{ textAlign: "left", mt: 2 }}
                    className={
                      isDarkMode
                        ? "headersubhead  chartheading_color"
                        : "headersubhead  light_text"
                    }
                  >
                    Assign Alert
                  </Typography>
                </Grid>
                {showAlert && (
                  <Alert variant="outlined" severity="error">
                    Alert Assignment Failed
                  </Alert>
                )}
                <Grid
                  item
                  lg={11.9}
                  md={11.9}
                  sm={12}
                  xs={12}
                  sx={{ mt: 3 }}
                  container
                >
                  <AssignAlert
                    isDarkMode={isDarkMode}
                    onAssignChange={onAssignChange}
                    onAssignSubmit={onAssignSubmit}
                    alertAssignDetails={alertAssignDetails}
                    handleDiscard={handleDiscard}
                  />
                </Grid>
              </>
            )}
          </Grid>
        }
      </div>
    </Layout>
  );
};
