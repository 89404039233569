export const TagModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height:'35%',
    bgcolor: 'background.paper',
    border: '1px solid #666666',
    borderRadius: '5px',
    p: 4,
    alignContent:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    '@media (max-width: 600px)': { // Adjust styles for small screens (sm and xs)
    width: '70%',
    height: '40%',
  },
  };
  export const statusUpdateModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '31% !important',
    height:'45% !important',
    bgcolor: 'background.paper',
    border: '1px solid #666666',
    borderRadius: '5px',
    p: 4,
    alignContent:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    '@media (max-width: 600px)': { // Adjust styles for small screens (sm and xs)
      width: '70% !important',
      height: '30% !important',
    },
  };
  export const CameraTagModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height:'25%',
    bgcolor: 'background.paper',
    border: '1px solid #666666',
    borderRadius: '5px',
    p: 4,
    alignContent:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    '@media (max-width: 600px)': { // Adjust styles for small screens (sm and xs)
      width: '70%',
      height: '30%',
    },
  };
  export const ExportModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    height:'35%',
    bgcolor: 'background.paper',
    border: '1px solid #666666',
    borderRadius: '5px',
    p: 4,
    alignContent:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  };
  export const ImportModalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height:'80%',
    bgcolor: 'background.paper',
    border: '1px solid #666666',
    borderRadius: '5px',
    p: 4,
    alignContent:'center',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  };
  export const ModalHead ={
    fontFamily: 'Nunito , sans-serif !important',
    fontSize: '24px !important',
    fontWeight: 700 ,
    lineHeight: '33px !important',
    letterSpacing: '0em !important',
    textAlign: 'left !important',
  }
//   export const algn
export const iconStyle = {
  fontSize: '20px',
  marginRight: '5px',
  color: '#ffffff',
};
export const iconDeleteStyle = {
  fontSize: '20px',
  marginRight: '5px',
  color: 'red',
};
