import { auditTrailRouteStartDateTime, dateOnly } from "../convertors/TimeConvertor";

export const areArraysEqual = (arr1, arr2) => {
    // Sort both arrays
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();

    // Check if lengths are equal
    if (sortedArr1.length != sortedArr2.length) {
        return false;
    }

    // Check if every element is equal
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
}
export const areEqualUTC = (datetime1, datetime2) => {
    // Create Date objects from the UTC datetime strings
    const date1 = new Date(datetime1);
    const date2 = new Date(datetime2);

    // Compare the timestamps
    return date1.getTime() === date2.getTime();
}
export const OrganisationDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];
    const getTypeName = (type) => {
        switch (type) {
            case 0:
                return 'Tracking';
            case 1:
                return 'Surveillance';
            case 2:
                return 'Tracking,Surveillance';
            default:
                return 'unknown';
        }
    }
    const getIsWisillicaStatus = (type) => {
        switch (type) {
            case true:
                return 'On';
            case false:
                return 'Off';

            default:
                return 'NA';
        }
    }

    // Compare relevant fields
    if (prevData.org_name != changeData.org_name) {
        changes.push(`organisation name from '${prevData.org_name}' to '${changeData.org_name}'`);
    }
    if (prevData.email != changeData.email) {
        changes.push(`email from '${prevData.email}' to '${changeData.email}'`);
    }
    if (prevData.phone != changeData.phone) {
        changes.push(`phone number from '${prevData.phone}' to '${changeData.phone}'`);
    }
    if (prevData.description != changeData.description) {
        changes.push(`About from '${prevData.description}' to '${changeData.description}'`);
    }




    if (prevData.license_no != changeData.license_no) {
        changes.push(`license number from '${prevData.license_no}' to '${changeData.license_no}'`);
    } if (prevData.logo != changeData.logo) {
        changes.push(`logo changed`);
    } if (prevData.type != changeData.type) {
        const prevTypeName = getTypeName(prevData.type);
        const changeTypeName = getTypeName(changeData.type);
        changes.push(`type of organisation from '${prevTypeName}' to '${changeTypeName}'`);
    } if (prevData.is_wisilica != changeData.is_wisilica) {
        const prevWise = getIsWisillicaStatus(prevData.is_wisilica);
        const changedWise = getIsWisillicaStatus(changeData.is_wisilica)
        changes.push(`wisilica organisation from '${prevWise}' to '${changedWise}'`);
    } if (prevData.org_type_id && changeData.org_type_id) {

        const isEqual = areArraysEqual(prevData.org_type_id, changeData.org_type_id);
        console.log("Previous org_type_id:", prevData.org_type_id);
        console.log("Current org_type_id:", changeData.org_type_id);
        console.log("isEqual elements are the same.", isEqual);

        if (!isEqual) {
            if (prevData.hasOwnProperty('sub_organizations') && changeData.hasOwnProperty('sub_organizations'))
                changes.push(`sub organisations from '${prevData.org_type_id.length != 0 ? prevData.sub_organizations : "none"}' to '${changeData.org_type_id != 0 ? changeData.sub_organizations : "none"}'`);
            else
                changes.push('sub organisations field changed')
        }

    }
    // Add more comparisons as needed

    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the organisation named ${prevData.org_name}`;
    } else {
        return
    }
}
export const CheckPointDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];
    const getTypeName = (type) => {
        switch (type) {
            case 3:
                return 'Patrol,Sentry';
            case 1:
                return 'Sentry';
            case 2:
                return 'Patrol';
            default:
                return 'unknown';
        }
    }
    if (prevData.check_point_name != changeData.check_point_name) {
        changes.push(`checkpoint name from '${prevData.check_point_name}' to '${changeData.check_point_name}'`);
    }
    if (prevData.checkpoint_type != changeData.checkpoint_type) {

        const prevTypeName = getTypeName(prevData.checkpoint_type);
        const changeTypeName = getTypeName(changeData.checkpoint_type);
        changes.push(`checkpoint type from '${prevTypeName}' to '${changeTypeName}'`);


    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the checkpoint named ${prevData.check_point_name}`;
    } else {
        return
    }
}
export const RouteDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];
    const getIsorder = (type) => {
        switch (type) {
            case 1:
                return 'On';
            case 0:
                return 'Off';
           
            default:
                return 'unknown';
        }
    }
    if (prevData.routename != changeData.routename) {
        changes.push(`Route name from '${prevData.routename}' to '${changeData.routename}'`);
    }
    // if (prevData.checkpoint_type != changeData.checkpoint_type) {

    // const prevTypeName = getTypeName(prevData.checkpoint_type);
    // const changeTypeName = getTypeName(changeData.checkpoint_type);
    // changes.push(`checkpoint type from '${prevTypeName}' to '${changeTypeName}'`);


    // }

    if (prevData.checkpoints && changeData.checkpoints) {
        const isEqual = areArraysEqual(prevData.checkpoints, changeData.checkpoints);
        if (!isEqual) {
            if (prevData.hasOwnProperty('checkpointsname') && changeData.hasOwnProperty('checkpointsname'))
                changes.push(`checkpoints from '${prevData.checkpoints.length != 0 ? prevData.checkpointsname : "none"}' to '${changeData.checkpoints != 0 ? changeData.checkpointsname : "none"}'`);
            else
                changes.push('sub organisations field changed')
        }
    }
    if (prevData.week_days && changeData.week_days) {
        const isEqual = areArraysEqual(prevData.week_days, changeData.week_days);
        if (!isEqual) {
            if (prevData.hasOwnProperty('week_days') && changeData.hasOwnProperty('week_days'))
                changes.push(`days from '${prevData.week_days.length != 0 ? prevData.week_days : "none"}' to '${changeData.week_days != 0 ? changeData.week_days : "none"}'`);
            else
                changes.push('days field changed')
        }
    }
    if (prevData.startiime && changeData.startiime) {
        const isEqualUtc = areEqualUTC(prevData.startiime, changeData.startiime)
        if (!isEqualUtc) {
            changes.push(`start time from '${auditTrailRouteStartDateTime(prevData.startiime)}' to '${auditTrailRouteStartDateTime(changeData.startiime)}' `);

        }
    }
    if (prevData.endtime && changeData.endtime) {
        const isEqualUtc = areEqualUTC(prevData.endtime, changeData.endtime)
        if (!isEqualUtc) {
            changes.push(`end time from '${auditTrailRouteStartDateTime(prevData.endtime)}' to '${auditTrailRouteStartDateTime(changeData.endtime)}' `);

        }
    }
    
    if (prevData.is_order != changeData.is_order) {
        const prevIsOrder = getIsorder(prevData.is_order);
        const currentIsOrder = getIsorder(changeData.is_order);

        changes.push(`Strictly follow order from '${prevIsOrder}' to '${currentIsOrder}'`);
    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the Route named ${prevData.routename}`;
    } else {
        return
    }
}

export const PersonnelDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];

    if (prevData.contractorname != changeData.contractorname) {
        changes.push(`Personnel name from '${prevData.contractorname}' to '${changeData.contractorname}'`);
    }
    if (prevData.emp_id != changeData.emp_id) {
        changes.push(`Employee Id from '${prevData.emp_id}' to '${changeData.emp_id}'`);
    }
    if (prevData.sitename != changeData.sitename) {
        changes.push(`Site from '${prevData.sitename}' to '${changeData.sitename}'`);
    }
    if (prevData.phone != changeData.phone) {
        changes.push(`Phone number from '${prevData.phone}' to '${changeData.phone}'`);
    }
    if (prevData.email != changeData.email) {
        changes.push(`email from '${prevData.email}' to '${changeData.email}'`);
    }if (prevData.serial_no != changeData.serial_no) {
        changes.push(`permit serial number from '${prevData.serial_no}' to '${changeData.serial_no}'`);
    }if (prevData.domain != changeData.domain) {
        changes.push(`domain/unit  from '${prevData.domain}' to '${changeData.domain}'`);
    }if (prevData.project != changeData.project) {
        changes.push(`project title  from '${prevData.project}' to '${changeData.project}'`);
    }if (prevData.project_maneger != changeData.project_maneger) {
        changes.push(`project manager from '${prevData.project_maneger}' to '${changeData.project_maneger}'`);
    }if (prevData.contractor_organisation_name != changeData.contractor_organisation_name) {
        changes.push(`awarded contrator from '${prevData.contractor_organisation_name}' to '${changeData.contractor_organisation_name}'`);
    }
    if (prevData.supervisor != changeData.supervisor) {
        changes.push(`supervisor from '${prevData.supervisor}' to '${changeData.supervisor}'`);
    }
    if (prevData.supervisor_phone != changeData.supervisor_phone) {
        changes.push(`supervisor phone from '${prevData.supervisor_phone}' to '${changeData.supervisor_phone}'`);
    }
    if (prevData.insurace_date && changeData.insurace_date) {
        const isEqualUtc = areEqualUTC(prevData.insurace_date, changeData.insurace_date)
        if (!isEqualUtc) {
            changes.push(`permit issuance date from '${dateOnly(prevData.insurace_date)}' to '${dateOnly(changeData.insurace_date)}' `);

        }
    }
    if (prevData.project_startdate && changeData.project_startdate) {
        const isEqualUtc = areEqualUTC(prevData.project_startdate, changeData.project_startdate)
        if (!isEqualUtc) {
            changes.push(`project start date & time from '${auditTrailRouteStartDateTime(prevData.project_startdate)}' to '${auditTrailRouteStartDateTime(changeData.project_startdate)}' `);

        }
    }
    if (prevData.project_enddate && changeData.project_enddate) {
        const isEqualUtc = areEqualUTC(prevData.project_enddate, changeData.project_enddate)
        if (!isEqualUtc) {
            changes.push(`project end date & time from '${auditTrailRouteStartDateTime(prevData.project_enddate)}' to '${auditTrailRouteStartDateTime(changeData.project_enddate)}' `);

        }
    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the Personnel named ${prevData.contractorname}`;
    } else {
        return
    }
}
export const SensorDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];

    if (prevData.sensor_name != changeData.sensor_name) {
        changes.push(`Sensor name from '${prevData.sensor_name}' to '${changeData.sensor_name}'`);
    }
    if (prevData.distance1 != changeData.distance1) {
        changes.push(`distance-1 from '${prevData.distance1}' to '${changeData.distance1}'`);
    } if (prevData.distance2 != changeData.distance2) {
        changes.push(`distance-2 from '${prevData.distance2}' to '${changeData.distance2}'`);
    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the sensor named ${prevData.sensor_name}`;
    } else {
        return
    }
}
export const BatteryDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];

    if (prevData.battery_name != changeData.battery_name) {
        changes.push(`battery name from '${prevData.battery_name}' to '${changeData.battery_name}'`);
    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the battery named ${prevData.battery_name}`;
    } else {
        return
    }
}
export const EncoderDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];

    if (prevData.title != changeData.title) {
        changes.push(`encoder name from '${prevData.title}' to '${changeData.title}'`);
    }
    if (prevData.Sensor != changeData.Sensor) {
        changes.push(` sensor from '${prevData.Sensor}' to '${changeData.Sensor}'`);
    }
    if (prevData.battery != changeData.battery) {
        changes.push(`battery from '${prevData.battery}' to '${changeData.battery}'`);
    }
   
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the encoder named ${prevData.title}`;
    } else {
        return
    }
}
export const UserDataChangeAnalyzer = (changeData, prevData, message) => {
    const changes = [];

    if (prevData.username != changeData.username) {
        changes.push(`name from '${prevData.username}' to '${changeData.username}'`);
    }
    if (prevData.rolename != changeData.rolename) {
        changes.push(` role from '${prevData.rolename}' to '${changeData.rolename}'`);
    }
    if (prevData.sitename != changeData.sitename) {
        changes.push(`site from '${prevData.sitename}' to '${changeData.sitename}'`);
    }
    if (prevData.email != changeData.email) {
        changes.push(`email from '${prevData.email}' to '${changeData.email}'`);
    }
    if (prevData.phone_number != changeData.phone_number) {
        changes.push(`phone from '${prevData.phone_number}' to '${changeData.phone_number}'`);
    }
    if (prevData.year_of_experience != changeData.year_of_experience) {
        changes.push(`year of experience from '${prevData.year_of_experience}' to '${changeData.year_of_experience}'`);
    }
    if (prevData.gender != changeData.gender) {
        changes.push(`gender from '${prevData.gender}' to '${changeData.gender}'`);
    }
    if (prevData.description != changeData.description) {
        changes.push(`description from '${prevData.description}' to '${changeData.description}'`);
    }
    if (prevData.profilepic != changeData.profilepic) {
        changes.push(`profile picture changed`);
    }
    if (changes.length > 0) {

        return `Updated: ${changes.join(', ')} for the user named ${prevData.username}`;
    } else {
        return
    }
}